import { Link } from "@StarberryUtils";
import React, { useState, useRef, useEffect } from "react";
//import ScrollAnimation from "react-animate-on-scroll";
import ReactReadMoreReadLess from "react-read-more-read-less"
import google from "../../../images/home/reviews/google-logo.svg"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap";
import useDeviceMedia from "../../utils/useDeviceMedia";
import "./Reviews.scss"
import axios from "axios";
// markup
const Reviews = (props) => {
    let [latestReview, setReviews] = useState(typeof props.reviews != "undefined" ? props.reviews : []);
    const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
    let [reviewCount, setReviewCount] = React.useState(typeof props.reviewCount != "undefined" ? props.reviewCount : 0);
    let [averageRating, setAverageRating] = React.useState(typeof props.averageRating != "undefined" ? props.averageRating : 0);
    const [renderComponent, setRenderComponent] = useState(false);
    const { isCustomMin } = useDeviceMedia({ min: "992px" });

    useEffect(() => {
        let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        if (latestReview.length == 0) {
            getitems(url);
        }
    })
    const sliderRef = useRef();
    const settings = {
        dots: false,
        lazyLoad: true,
        speed: 800,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        autoplay: false
    }

    const next = () => {
        sliderRef.current.slickNext();
    }
    const previous = () => {
        sliderRef.current.slickPrev();
    }
    const strapiconfig = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }

    const getitems = async url => {
        try {
            const { data } = await axios.get(url, strapiconfig)// could be from env files  
            if (data) {
                const reviews = data.filter(rev => rev.comment != null && rev.starRating == "FIVE");
                reviews.sort((a, b) => b.updateTime - a.updateTime);
                setReviewCount(data.length);
                let sumOfScores = 0;
                data.map((review) => {
                    const reviewStringValue = review.starRating;
                    sumOfScores += wordToNumber[reviewStringValue];
                })
                setAverageRating(Math.round(sumOfScores / data.length * 10) / 10);
                setReviews(reviews.slice(0, 10));
            }

        } catch (error) {
            // cache it if fail/error;
            console.error(error)
        }
    }

    const image_url = props.image.url

    let processedImages = JSON.stringify({});
    if (props.imagetransforms?.Modules?.Google_Reviews?.Reviews_Background_Image_Transforms) {
        processedImages = props.imagetransforms.Modules.Google_Reviews.Reviews_Background_Image_Transforms;
    }

    return (
        <React.Fragment>

            <section className="reviews home-one-review">
                <Container fluid={isCustomMin}>
                    <Row>

                        <Col lg={6} className="d-flex block-left">
                            <div animateIn='fadeInLeft' animateOnce className="reviews-info">
                                <div className="reviews-item-wrap">
                                    <div className="reviews-wrapper">
                                        <Link to="/about-dacha/customer-testimonials" className="google-brand">
                                            <img loading="lazy" src={google} alt="google - Dacha" width={146} height={60} />
                                        </Link>
                                        <div className="ratings">
                                            <div className="rating text-sm">
                                                <span className="text-sm-bold average-rating">{averageRating}/5</span> <span className="text-sm">Rating from {reviewCount} Reviews</span>
                                            </div>
                                            <Link to="/about-dacha/customer-testimonials" className="view-more text-xs">
                                                View more reviews
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="review-slider">
                                        <Slider {...settings} ref={sliderRef}>
                                            {latestReview && latestReview.map((review, i) => {
                                                return (
                                                    <div key={i} className="outline-none">
                                                        <ul className="stars-icon">
                                                            <li>
                                                                <i className="icon-stars">

                                                                </i>
                                                            </li>
                                                            <li>
                                                                <i className="icon-stars">

                                                                </i>
                                                            </li>
                                                            <li>
                                                                <i className="icon-stars">

                                                                </i>
                                                            </li>
                                                            <li>
                                                                <i className="icon-stars">

                                                                </i>
                                                            </li>
                                                            <li>
                                                                <i className="icon-stars">

                                                                </i>
                                                            </li>
                                                        </ul>
                                                        <p className="review-comments text-sm">
                                                            {review.comment && (
                                                                <ReactReadMoreReadLess
                                                                    charLimit={126}
                                                                    readMoreText={"More"}
                                                                    readLessText={"Less"}
                                                                    readMoreClassName="read-more-less--more more-content"
                                                                    readLessClassName="read-more-less--less more-content"
                                                                >
                                                                    {review.comment}
                                                                </ReactReadMoreReadLess>
                                                            )}
                                                        </p>
                                                        <div className="clients">
                                                            <div className="clients-img">
                                                                <img loading="lazy" src={review.reviewer?.profilePhotoUrl} alt={`${review.reviewer?.displayName} - Dacha`}
                                                                    height={48} width={48} />
                                                            </div>
                                                            <div className="clients-info">
                                                                <h6 className="text-md-bold"> {review.reviewer?.displayName}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col lg={6} className="d-flex px-0 block-right">
                            <div animateIn='fadeInRight' animateOnce className="reviews-img img-zoom">
                                {image_url &&
                                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename="articles.Modules.Google_Reviews.Reviews_Background_Image.reviews_image" attr={{ alt: `${props.image?.alternativeText} - Dacha` }} imagetransformresult={processedImages} id={props.id} />
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}
export default Reviews