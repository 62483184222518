import { Link } from "@StarberryUtils";
import * as React from "react"
import HTMLReactParser from 'html-react-parser';


import PlayVideo from "../../Play/custom-video";
import { Button, Container, Form } from 'react-bootstrap';
// Images
import BannerLarge from "../../../images/home/video-banner-medium.jpg"
import BannerMedium from "../../../images/home/video-banner-medium.jpg"
import BannerSmall from "../../../images/home/video-banner-medium.jpg"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

// styles
//import ScrollAnimation from "react-animate-on-scroll";
import "./Banner.scss"
// markup

const AreaGuideBanner = (props) => {
  let [isPlay, setPlay] = React.useState(false);

  const image_url = props.image.url

  let processedImages = JSON.stringify({});
  if (props.page?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.page.imagetransforms.Banner_Image_Transforms;
  } 

  return (
    <React.Fragment>
      <div className="main-banner area-guide-header">
        <div className="main-banner-embed background-shadow d-xl-flex align-items-xl-stretch">
          <ImageTransform imagesources={image_url} renderer="srcSet" imagename="area-guides.Banner_Image.details" attr={{ alt: `${props.image.alternativeText} - Dacha`, class: '' }} imagetransformresult={processedImages} id={props.id} />

          {props.video &&
            <div className="banner-btn">
              <PlayVideo url={props.video}
              />
            </div>
          }
        </div>
        <div className="main-banner-content">
          <Container>
            <div className="banner-text">
              <h1>{props?.Title ? props.Title : `Living in ${props?.name}`}</h1>
              {props.content &&
                <div animateIn='fadeInUp' animateOnce>
                  {HTMLReactParser(props.content.replace(/\n/g, ''))}
                </div>
              }
              <div className="banner-btns">
                <Link to={`/properties/for-sale/in-${props.url}`} className="btn btn-primary">{props.cta_1}</Link>
                <Link to={`/${props.cta_2_link.URL}`} className="btn btn-outline btn-link">{props.cta_2}</Link>
              </div>
              <p></p>
              {props.info &&
                <div className="connect">
                  <span className="connect-us">
                    <a className="call-us">
                      <i className="icon-call-saffron">
                      </i>
                    </a>
                  </span>
                  <span className="tel">
                    {HTMLReactParser(props.info.replace(/\n/g, '<br />'))}
                  </span>
                </div>
              }

            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AreaGuideBanner