import { Link } from "@StarberryUtils";
import * as React from "react"
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
//import ScrollAnimation from "react-animate-on-scroll";
import { useStaticQuery, graphql } from "gatsby";
import HTMLReactParser from 'html-react-parser';
import "./Contact.scss"
import { GetURL } from "../../common/site/functions"
import BookAViewForm from "../../forms/book-a-viewing-form";
import ValuationFormPage from "../../forms/valuation-form";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import useDeviceMedia from "../../utils/useDeviceMedia";
// markup
const AreaGuideStaticContact = () => {
    const { isCustomMin } = useDeviceMedia({ min: "992px" });
    const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false);
    const data = useStaticQuery(graphql`
    query {
      glstrapi {
	    globalModule {
            Area_Guide_Details  {
                Get_Started_Content
                Get_Started_CTA_1_Label
                Get_Started_CTA_2_Label
                Get_Started_CTA_2_Link {
              URL
              id
              Link_Type
            }
               Get_Started_CTA_1_Link {
              URL
              id
              Link_Type
            }
                image {
                  url
                }
                Title
                id
                _id
            }
        Get_Started_Content
        Get_Started_CTA_1_Label
        Get_Started_CTA_2_Label
        Get_Started_CTA_1_Link {
              URL
              id
              Link_Type
            }
            Get_Started_CTA_2_Link {
              URL
              id
              Link_Type
            }
	    }
        siteConfig {
			Address
			Email
			Phone
			Available_Timings
		  }
      }
    }
  `);



    const content = data.glstrapi.globalModule

    const ctaAction = (type, label, action) => {
        var cls = "btn btn-primary";
        if (type === 2) {
            cls = "btn btn-secondary";
        }
        if (action === "Banner_Search") {
            return <Button className={`${cls} banner_search`}>{label}</Button>
        } else if (action === "Request_Callback") {
            return <a href="javascript:void(0)" className={`${cls} max-width-high`} onClick={openBookViewformModal}>{label}</a>
        } else if (action === "Request_Valuation") {
            return <a href="javascript:void(0)" className={`${cls} max-width-high`} onClick={openValuationformModal}>{label}</a>
        }
    };

    const ctaLink = (type, label, link) => {
        var cls = "btn btn-primary";
        if (type === 2) {
            cls = "btn btn-secondary";
        }
        if (link.Link_Type === "Internal") {
            return <Link to={`/${GetURL(link.id)}`} className={cls}>{label}</Link>
        } else if (link.Link_Type === "External") {
            return <a href={`${GetURL(link.id)}`} target="_blank" className={cls}>{label}</a>
        }
    };
    const openBookViewformModal = () => {
        setBookViewingformOpen(true);
    };
    const closeBookViewformModal = () => {
        setBookViewingformOpen(false);
    };

    const [modalValuationformOpen, setValuationformOpen] = React.useState(false);
    const openValuationformModal = () => {
        setValuationformOpen(true);
    };
    const closeValuationformModal = () => {
        setValuationformOpen(false);
    };
    const image_url = content?.Area_Guide_Details?.image[0]?.url;
    console.log(image_url, "image_url");
    const image_url1 = content.Area_Guide_Details.image[0].url;
    console.log(image_url1, "image_url");

    return (
        <React.Fragment>
            <div className="footer-block contact">
                <Container fluid={isCustomMin}>
                    <Row>
                        <Col lg={7} className="d-flex px-0 block-left">
                            <div className="contact-us">
                                {content.Area_Guide_Details.Title && <h4>{content.Area_Guide_Details.Title}</h4>}
                                {content.Area_Guide_Details &&
                                    <div className="footer-block-text text-sm">{HTMLReactParser(content.Area_Guide_Details.Get_Started_Content.replace(/<br\s*\/?>/gi, ''))}</div>
                                }
                                <div className="btn-wrapper">
                                    {content.Area_Guide_Details.Get_Started_CTA_1_Label && content.Area_Guide_Details.Get_Started_CTA_1_Link &&
                                        ctaLink(1, content.Area_Guide_Details.Get_Started_CTA_1_Label, content.Area_Guide_Details.Get_Started_CTA_1_Link)
                                    }
                                    {content.Area_Guide_Details.Get_Started_CTA_1_Label && content.Area_Guide_Details.Get_Started_CTA_1_Label &&
                                        ctaAction(1, content.Area_Guide_Details.Get_Started_CTA_1_Label, content.Area_Guide_Details.Get_Started_CTA_1_Label)
                                    }
                                    {content.Area_Guide_Details.Get_Started_CTA_2_Label && content.Area_Guide_Details.Get_Started_CTA_2_Link &&
                                        ctaLink(2, content.Area_Guide_Details.Get_Started_CTA_2_Label, content.Area_Guide_Details.Get_Started_CTA_2_Link)
                                    }
                                    {content.Area_Guide_Details.Get_Started_CTA_2_Label && content.Area_Guide_Details.Get_Started_CTA_1_Label &&
                                        ctaAction(2, content.Area_Guide_Details.Get_Started_CTA_2_Label, content.Area_Guide_Details.Get_Started_CTA_1_Label)
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={5} className="p-0 d-none d-lg-flex block-right">
                            <div className="block-image">
                                <img
                                    src={image_url} // Directly set the image source URL
                                />
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default AreaGuideStaticContact